.contact-us-container {
    background: url("../images/Contactus-Photo33.png") center no-repeat;
    background-size: cover;
    height: 60vh;
    border-radius: 12px;
    position: relative;
}

.contact-us-container > div {
    padding-left: 5%;
    padding-top: 15%;
    position: absolute;
}

.Contactus-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #777777;
}

.contact-us-container > div > .home-subtitle {
    color: #194389;
}

.Contactus-details {
    width: 249px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #777777;
}

.Contactus-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-transform: capitalize;
    color: #181818;
}

.Contactus-message-details {
    width: 962px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #777777;
}

.contactus-first-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #777777;
}

.contactus-input {
    width: 568px;
    height: 56px;
    background: #F9F9F9;
    border-radius: 6px;
    border: #F9F9F9;
}

.contactus-txt {
    width: 568px;
    height: 179px;
    background: #F9F9F9;
    border-radius: 6px;
    border: #F9F9F9;
    padding: 2% 3%;
}

.contactus-input::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #B6B6B6;
}

.ContactUs-select {
    padding-left: 5%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #B6B6B6;
}

.Contactus-btn {
    width: 222px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}

.our-location {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-transform: capitalize;
    color: #181818;
}

.our-location-details {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #777777;
    width: 70%;
    margin: auto;
}

/*slides*/
.slideshow {
    margin: 0 auto;
    overflow: hidden;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.location-container > .slick-slider > .slick-dots > li > button:before {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 0 0;
    background-color: #D9D9D9;
    content: '';
    opacity: 1;
}

.location-container > .slick-slider > .slick-dots > .slick-active > button:before {
    background-color: #11448A;
}

.location-container {
    margin: 5% 0 10%;
}

.location-container > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
    margin-right: 5%;
}

.location {
    position: relative;
    border-radius: 12px;
    width: 100% !important;
    height: 35vh;
}

.location > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    padding: 5%;
    padding-top: 50%;
    height: 100%;
    transition: .5s ease-in-out;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) 50%);
}

.location:hover .overlay {
    height: 100%;
    padding-top: 20%;
    background-color: rgba(0, 0, 0, 0.6);
}

.location-name {
    font-size: 28px;
}

.location-address {
    text-transform: capitalize;
    display: none;
    margin: 5% 0;
    font-size: 14px;
}

.location:hover .location-address, .location:hover .location-link {
    display: block;
}

.location-link {
    display: none;
    text-decoration: none;
    color: white;
    padding: 0;
    font-size: 14px;
}

.location-link:hover {
    color: white;
    text-decoration: underline;
}

@media (max-width: 1300px) {
    .Contactus-message-details {
        width: 100%;
    }

    .contact-us-form > div > .d-flex {
        width: 100%;
    }

    .contact-us-form > div > .d-flex > div {
        width: 50%;
    }

    .contactus-input {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .contact-us-container > div {
        padding-top: 20%;
    }

    .contact-details > .d-flex {
        display: block !important;
    }

    .contact-details > .d-flex > div {
        width: 100% !important;
        margin-bottom: 5%;
    }

    .contact-details > .d-flex > div > div {
        justify-content: center;
    }
}

@media (max-width: 1100px) {
    .our-location-details {
        width: 100%;
    }
}

@media (max-width: 950px) {
    .contact-us-container > div {
        padding-top: 25%;
    }
}

@media (max-width: 850px) {
    .contact-us-form > div > .d-flex {
        display: block !important;
    }

    .contact-us-form > div > .d-flex > div {
        width: 100%;
    }

    .contactus-input {
        margin-bottom: 5%;
    }
}

@media (max-width: 750px) {
    .contact-us-container > div {
        padding-top: 35%;
    }
}

@media (max-width: 500px) {
    .contact-us-container > div {
        padding-top: 50%;
    }

    .Contactus-subtitle {
        font-size: 14px;
        line-height: normal;
    }

    .Contactus-details {
        font-size: 10px;
        line-height: normal;
    }

    .Contactus-message, .our-location {
        font-size: 24px;
        line-height: normal;
    }

    .Contactus-message-details, .our-location-details {
        font-size: 12px;
        line-height: 24px;
    }

    .contactus-first-name {
        font-size: 15px;
        line-height: normal;
    }

    .location-name {
        font-size: 22px;
        line-height: normal;
    }
}

@media (max-width: 320px) {
    .contact-us-container > div {
        padding-top: 70%;
    }
}
