.Home-image {
    width: 100%;
    height: 65vh;
    background: url(../images/Home1.png) center no-repeat;
    background-size: cover;
    border-radius: 12px;
    position: relative;
}

.Home-image > div {
    padding-left: 8%;
    padding-top: 10%;
    position: absolute;
}

.home-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-color);
}

.home-subtitle {
    width: 52%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
}

.shop-now-btn {
    margin-top: -20px;
    font-size: 18px;
}

.shop-now-btn:hover {
    background: transparent;
}

.categories {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #181818;
}

/*slids*/
.slideshow {
    margin: 0 auto;
    overflow: hidden;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #D9D9D9;
}

.slideshowDot.active {
    background-color: #11448A;
}

.new-arrivals {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #181818;
}

.card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #393939;
}

.card-price {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
    color: #777777;
}

.Pricetag {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}

.card {
    border: #ffffff;
}

.cardlbl {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
    border-radius: 6px;
    text-align: center;
    padding: 2% 5%;
}

.slideshowDot2 {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #D9D9D9;
}

.slideshowDot2.active {
    background-color: #E1211D;
}

.Expand-btn {
    width: 40px;
    height: 40px;
    background: url(../images/Expand.png) center no-repeat;
    border-radius: 50%;
}

/*banner*/
.bannerlbl {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    color: #FAF8F8;
}

.bannername {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #EFEFEF;
}

.bannerbtn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}

.bannerimg {
    filter: drop-shadow(0px 22px 35px rgba(0, 0, 0, 0.25));
    margin-left: 10%;
    top: -3%;
    position: absolute;
    height: 20em;
}

.speaker-image {
    top: -4%;
    margin-left: 5%;
}

.bannerimg1 {
    background-color: transparent;
    margin: auto;
    height: 24em;
    margin-top: 5%;
    margin-bottom: -3%;
}

.bannerimg-container {
    position: relative;
}

.featured-products {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #181818;
}

.mv-title {
    width: 662px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    text-transform: capitalize;
    color: #181818;
}

.mv-subtitle {
    width: 962px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #777777;
}

.frame-number {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    color: #11448A;
}

.frame-lbl {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    text-transform: capitalize;
    color: #5C5C5C;
}

.mv-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #393939;
}

.mv-mv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #777777;
}

.feedbacks-title {
    width: 441px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    text-transform: capitalize;
    color: #181818;
}

.feedbacks-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    color: #777777;
}

.feedbacks-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #11448A;
}

.feedbacks-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #B6B6B6;
}

.endframe-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #393939;
}

.endframe {
    width: 70%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #777777;
    margin: auto;
}

#heart-svg {
    cursor: pointer;
    overflow: visible;
    width: 30px;
}

svg #heart {
    transform-origin: center;
    animation: animateHeartOut .3s linear forwards;
}

svg #main-circ {
    transform-origin: 29.5px 29.5px;
}

.checkbox {
    display: none;
}

.checkbox:checked + label svg #heart {
    transform: scale(0.2);
    fill: #E2264D;
    animation: animateHeart .3s linear forwards .25s;
}

.checkbox:checked + label svg #main-circ {
    transition: all 2s;
    animation: animateCircle .3s linear forwards;
    opacity: 1;
}

@keyframes animateHeart {
    0% {
        transform: scale(0.2);
    }

    40% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.mission-container {
    margin-bottom: 10%;
}

.mission-container > div:first-child {
    width: 60%;
    background: url("../images/photo1.svg") center no-repeat;
    background-size: cover;
    border-radius: 0 6px 6px 0;
}

.mission-container > div:last-child {
    width: 40%;
}

.mission-container > div:last-child > .d-flex {
    width: 100%;
}

.mission-container > div:last-child > .d-flex > div {
    width: 100%;
    background-color: #F7F8FF;
    border-radius: 6px 0 0 6px;
}

.brandslide {
    margin-top: 4em;
}

.partner-container {
    margin: 0 15%;
}

.partner-container > div {
    width: 100%;
    justify-content: center;
}

.partner-container > img {
    padding-left: 0px;
}

.testimonial-container {
    margin-top: 5%;
    margin-bottom: 10%;
}

.testimonial {
    background-color: #F9F9F9;
    border-radius: 6px;
    padding: 5%;
    margin-right: 5%;
    width: auto !important;
    min-width: 95%;
}

.testimonial-message-container {
    margin-top: 2%;
    white-space: pre-line;
}

.why-us-container {
    margin-bottom: 10%;
}

.why-us-container > div {
    width: 100%;
}

.why-us-container > div > div {
    width: 100%;
    justify-content: center;
}

.why-us-container > div > div > div {
    width: calc(100% / 3);
}

.category-container {
    margin: 5% 0;
    padding: 2% 0 5%;
}

.category-container > .container > .slick-slider > .slick-dots > li > button:before,
.product-container > .container > .slick-slider > .slick-dots > li > button:before {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 0 0;
    background-color: #D9D9D9;
    content: '';
    opacity: 1;
}

.category-container > .container > .slick-slider > .slick-dots > .slick-active > button:before {
    background-color: #11448A;
}

.category {
    position: relative;
    border-radius: 12px;
    margin-right: 10%;
    width: 100% !important;
    text-align: center;
    padding: 5%;
    text-decoration: none;
}

.category:hover {
    text-decoration: none;
}

.category:hover > div {
    background-color: var(--primary-color);
}

.category > div {
    border: 1px solid #B6B6B6;
    border-radius: 12px;
    margin: 0 10% 10%;
    padding: 10%;
}

.category > div > img {
    width: 100%;
    height: 15vh;
    border-radius: 12px;
}

.category > div > .hover-image {
    display: none;
}

.category:hover > div > img {
    display: none;
}

.category:hover > div > .hover-image {
    display: block;
}

.category-name {
    font-size: 18px;
    margin: 5% 0;
    color: black;
}

.no-products {
    text-align: center;
    color: #B6B6B6;
    font-size: 32px;
    margin-top: -5%;
}

.product-container {
    margin: 5% 0;
}

.product {
    margin-top: 5%;
    margin-bottom: 10%;
    padding: 2% 15%;
}

.product > .card > .card-header {
    border-radius: 12px;
    box-shadow: 0 16px 60px 0 #00000014;
    background-color: white;
    padding: 5%;
}

.product > .card > .card-header > img {
    margin-top: 5%;
}

.product-container > .container > .slick-slider > .slick-dots > .slick-active > button:before {
    background-color: #E1211D;
}

.request-product-card {
    margin-top: 0 !important;
}

.mobile-version {
    display: none;
}

.products .card {
    border-radius: 15px;
    height: 100%;
}

.MV {
    width: 100%;
    margin: 0;
}

.MV-photo {
    background: url("../images/photo1.png") bottom no-repeat;
    background-size: cover;
    border-radius: 0 6px 6px 0;
}

.MV .col-12 > .row {
    margin-left: 4vh;
}

.products {
    margin: 0 15%;
}

.products > .col-12 > .row {
    height: 100%;
}

.products > .col-12 > .row > .col-12:first-child {
    margin-bottom: 2%;
}

.products > .col-12 > .row > .col-12:last-child {
    margin-top: 2%;
}

.stat-container {
    padding: 0 15%;
}

.benefit-container {
    margin-bottom: 10%;
    padding: 0 10%;
}

@media (max-width: 1650px) {
    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-5 {
        width: 60%;
        margin-left: 0 !important;
        padding-left: 10%;
    }

    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-6 {
        width: 40%;
    }
}

@media (max-width: 1600px) {
    .products {
        margin: 0 10%;
    }

    .bannerimg {
        margin-left: -10%;
    }

    .speaker-image {
        margin-left: -25%;
    }
}

@media (max-width: 1500px) {
    .Home-image > div {
        padding-left: 15%;
    }

    .product {
        padding: 2% 10%;
    }
}

@media (max-width: 1400px) {
    .benefit-container {
        padding: 0 5%;
    }

    .product {
        width: 100% !important;
    }

    .bannerimg {
        margin-left: -25%;
    }

    .speaker-image {
        margin-left: -40%;
    }
}

@media (max-width: 1300px) {
    .Home-image > div {
        padding-left: 10%;
        padding-top: 12%;
    }

    .products {
        margin: 0 10%;
    }

    .partner-container {
        margin: 0 10%;
    }
}

@media (max-width: 1200px) {
    .bannerimg {
        margin-left: -35%;
    }

    .speaker-image {
        margin-left: -50%;
    }
}

@media (max-width: 1150px) {
    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-5 {
        width: 70%;
    }

    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-6 {
        width: 30%;
    }

    .bannerimg {
        margin-left: -85%;
        width: 250%;
    }

    .speaker-image {
        top: 0;
    }
}

@media (max-width: 1100px) {
    .MV > .col-12 {
        width: 50%;
    }

    .benefit-container {
        padding: 0;
    }

    .partner-container {
        margin: 0 5%;
    }
}

@media (max-width: 1000px) {
    .stat-container {
        padding: 0 10%;
    }

    .stat-container > div > h1 {
        width: 100% !important;
    }
}

@media (max-width: 992px) {
    .products {
        margin: 0 5%;
    }

    .products > .col-12 {
        width: 100% !important;
    }

    .pc-version {
        display: none;
    }

    .mobile-version {
        display: block;
    }

    .products > .col-12:nth-child(2) .card {
        margin-top: 3.5%;
    }

    .products > .col-12:nth-child(2) .card .bannerimg {
        margin-left: 60%;
        width: 50%;
        top: 10%;
        position: relative;
    }

    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-5 {
        width: 40%;
    }

    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-7 {
        width: 60%;
    }

    .partner-container {
        margin: 0;
    }

    .partner-container div:nth-child(3) {
        display: none;
    }

    .partner-container div:nth-child(4) {
        display: none;
    }

    .partner-container div:nth-child(5) {
        display: none;
    }

    .partner-container div:nth-child(6) {
        display: none;
    }

    .benefits .col-12 {
        width: 100%;
        margin: 0 0 7vh 0;
    }

    .benefits .col-12 .endframe {
        width: 40%;
    }
}

@media (max-width: 950px) {
    .Home-image {
        background-size: cover;
        background-position: center;
    }

    .Home-image > div {
        padding-top: 18%;
    }

    .home-title {
        font-size: 11px;
    }

    .home-subtitle {
        font-size: 24px;
        width: 75%;
        line-height: 32px;
    }
}

@media (max-width: 900px) {
    .MV > .col-12 {
        width: 100%;
    }

    .MV > .col-12 > .row {
        margin-left: 0;
        width: 100%;
    }

    .MV-photo {
        height: 50vh;
        margin-bottom: 5%;
    }
}

@media (max-width: 850px) {
    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-5 {
        width: 50%;
    }

    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-7 {
        width: 50%;
    }
}

@media (max-width: 800px) {
    .Home-image > div {
        padding-top: 20%;
    }
}

@media (max-width: 700px) {
    .Home-image > div {
        padding-top: 25%;
    }

    .bannerlbl {
        font-size: 10px;
    }

    .bannername {
        font-size: 20px;
    }

    .bannerbtn {
        font-size: 10px;
    }

    .stat-container {
        padding: 0;
    }

    .benefits .col-12 .endframe {
        width: 70%;
    }
}

@media (max-width: 576px) {
    .mobile-version > .row > .col-sm-7 {
        text-align: center;
    }

    .mobile-version > .row > .col-sm-7 > *, .mobile-version > .row > .col-sm-7 > a > button {
        margin-left: 0 !important;
    }
}

@media (max-width: 500px) {
    .benefits .col-12 .endframe {
        width: 100%;
    }

    .categories, .new-arrivals, .featured-products {
        font-size: 22px;
        line-height: normal;
    }

    .stat-container > div > h1, .feedbacks-title {
        font-size: 24px !important;
    }

    .stat-container > div > .row > .col-12 > p:first-child {
        font-size: 40px !important;
    }

    .para-text {
        font-size: 14px !important;
    }

    .mv-name {
        font-size: 18px;
    }

    .mv-mv, .feedbacks-date, .feedbacks-text, .endframe {
        font-size: 12px;
        line-height: 24px;
    }

    .feedbacks-name {
        font-size: 16px;
    }

    .endframe-name {
        font-size: 13px;
        line-height: normal;
    }
}

@media (max-width: 450px) {
    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-5,
    .products > .col-12 > .row > .col-12 > .card > .row > .col-sm-7 {
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .products > .col-12:nth-child(2) .card .bannerimg {
        margin-left: 0;
    }

    .partner-container > .container {
        max-width: 100%;
    }
}
