.loading {
    text-align: center;
    padding: 15%;
}

@media (max-width: 768px) {
    .loading > div > svg {
        width: 100% !important;
        height: 100% !important;
    }
}
