.subtitles-footer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #393939;
}

.links {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #777777;
}

.links.nav-link:hover {
    color: #777777 !important;
}

.nav-link:hover > .links {
    color: var(--primary-color);
}

.support-container {
    margin-bottom: 10%;
}

.support-container > p > .nav-link, .social-media-link-container > div > .nav-link {
    padding: 5% 5% 2% 0;
}

.support-container > p > .d-flex {
    margin-top: 8%;
}

.support-container > p > .d-flex > img {
    height: 100%;
    align-self: center;
}

.support-container > p > .d-flex > .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    align-self: center;
}

.support-container > p > .d-flex > .nav-link > h1 {
    margin-bottom: 0;
}

.footer-searchbar {
    width: 80%;
    height: 40px;
    border-radius: 6px 0 0 6px;
}

#newsletterEmail::placeholder {
    padding-left: 15%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    
    background-image: url(../images/Vector.png);
    background-repeat: no-repeat;
    background-position: 2%;
}

.subscribe-btn {
    border-radius: 0 6px 6px 0;
    width: 20%;
}

.footer-end {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 2%;
}

.footer-end > a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #777777;
}

.payment-logo-container > div, .social-media-link-container > div {
    margin-right: 5%;
}

@media (max-width: 1650px) {
    .subscribe-btn {
        width: 30%;
    }
}

@media (max-width: 1200px) {
    .subscribe-btn {
        width: 35%;
    }
}

@media (max-width: 1000px) {
    footer > .container > .d-flex > .p-2 > .d-flex {
        display: block !important;
    }

    footer > .container > .d-flex > .p-2 > .d-flex > div {
        width: 100% !important;
    }

    .support-container > p > .nav-link, .social-media-link-container > div > .nav-link {
        padding: 2% 0 0;
    }

    .footer-searchbar {
        width: 50%;
        padding: 3%;
    }

    .subscribe-btn {
        width: 20%;
    }
}

@media (max-width: 600px) {
    .footer-searchbar {
        width: 100%;
    }

    .subscribe-btn {
        width: 30%;
    }
}
