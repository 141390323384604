.about-us-container {
    background: url("../images/about-us.png") center no-repeat;
    background-size: cover;
    height: 60vh;
    border-radius: 12px;
    position: relative;
}

.about-us-container > div {
    padding-left: 5%;
    padding-top: 15%;
    position: absolute;
}

.about-us-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
}

.about-us-container > div > .home-subtitle {
    color: white;
}

@media (max-width: 1200px) {
    .about-us-container > div {
        padding-top: 20%;
    }
}

@media (max-width: 1000px) {
    .about-us-detail-container > .row > .col-md-4 {
        width: 40%;
    }

    .about-us-detail-container > .row > .col-md-8 {
        width: 60%;
    }
}

@media (max-width: 900px) {
    .about-us-detail-container > .row > .col-md-4, .about-us-detail-container > .row > .col-md-8 {
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }

    .about-us-detail-container > .row > div > img {
        height: 50vh;
        object-fit: cover;
        object-position: bottom;
    }
}

@media (max-width: 950px) {
    .about-us-container > div {
        padding-top: 25%;
    }
}

@media (max-width: 750px) {
    .about-us-container > div {
        padding-top: 35%;
    }
}

@media (max-width: 500px) {
    .about-us-container > div {
        padding-top: 50%;
    }

    .about-us-detail-container > .row > div > h1 {
        font-size: 24px !important;
        line-height: normal;
    }

    .about-us-detail-container > .row > div > .para-text {
        font-size: 12px !important;
        line-height: 24px;
    }
}

@media (max-width: 320px) {
    .about-us-container > div {
        padding-top: 70%;
    }
}

