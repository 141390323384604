.breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
    color: #777777;
    font-size: 18px;
}

.breadcrumb-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #777777;
}

.Product-Title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 40px;
    color: #181818;
}

.Product-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: #194389;
}

.Product-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #777777;
    white-space: pre-line;
    margin-top: -5%;
}

.Product-selection {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #777777;
}

.Add-btn {
    width: 194px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #11448A;
}

.get-btn {
    width: 204px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #F9F9F9;
}

.btn-add-product {
    background: #F9F9F9;
    border-radius: 0px 6px 6px 0px;
    font-size: 16px;
}

.btn-add-product1 {
    background: #F9F9F9;
    border-radius: 6px 0px 0px 6px;
    font-size: 16px;
}

.product-num {
    width: 52px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #393939;
    background: #EFEFEF;
    border: none;
}

.button {
    background-color: #ffffff;
    border: #ffffff;
    border-radius: 15px;
}

.bg-disabled {
    background-color: #ffffff;
    border: #ffffff;
    border-radius: 15px;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    border: #ffffff;
}

.volume-btn {
    width: 40.16px;
    height: 40.16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    align-items: center;
    color: #5C5C5C;
    border: #ffffff;
    padding: 0 !important;
}

.product-color-black {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #777777;
    border: 1px solid #E0E0E0;
    border-radius: 50% !important;
    opacity: 1 !important;
}

.product-color-black:hover {
    background-color: #17161A;
}

.product-color-gray {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #ECF1F4;
    border: 1px solid #E0E0E0;
}

.product-color-gray:hover {
    background-color: #ECF1F4;
}

.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left
}

.rating > input {
    display: none
}

.rating > label {
    position: relative;
    width: 1em;
    font-size: 30px;
    font-weight: 300;
    color: red;
    cursor: pointer;
}

.rating > label::before {
    content: "\2605";
    position: absolute;
    opacity: 0;
}

.rating > input:checked ~ label:before {
    opacity: 1;
}

.selected-size-button {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}

.product-image {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    border-radius: 12px;
    object-fit: cover;
}

.product-slider-image {
    width: 173px;
    height: 173px;
    border-radius: 12px;
    object-fit: cover;
}

.product-detail-container > .d-flex > div {
    width: 50%;
}

.product-image-container {
    margin-right: 5%;
}

@media (max-width: 1500px) {
    .product-image-container {
        margin-right: 0;
    }
}

@media (max-width: 1400px) {
    .product-slider-image {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 1200px) {
    .product-image-container {
        width: 60%;
    }

    .product-detail-container > .d-flex > div:last-child {
        width: 40%;
    }
}

@media (max-width: 1000px) {
    .product-detail-container > .d-flex {
        display: block !important;
    }

    .product-detail-container > .d-flex > div {
        width: 100% !important;
    }

    .product-detail-container > .d-flex > div:first-child {
        margin-bottom: 5%;
    }

    .product-image-container > div {
        margin-right: 0 !important;
    }

    .request-product-card > .card-body {
        width: 40%;
    }

    .request-product-card > .col-md-3:first-child {
        width: 35%;
    }

    .request-product-card > .col-md-3:last-child {
        width: 20%;
    }

    .request-product-card > .col-md-3 > img {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .Pricetag {
        width: 100%;
    }

    .request-product-card > .card-body {
        width: 60%;
    }

    .request-product-card > .col-md-3:first-child {
        width: 30%;
    }

    .request-product-card > .col-md-3:last-child {
        width: 10%;
        padding: 0;
    }

    .request-product-card > .col-md-3 > button {
        width: 40px;
        height: 40px;
    }

    .request-product-card > .col-md-3 > button > img {
        width: 100%;
    }

    .request-product-card > .card-body > .card-title,
    .request-product-card > .card-body > div > div > .card-text {
        font-size: 11px !important;
        line-height: normal !important;
    }

    .product-color-black {
        width: 20px;
        height: 20px;
    }

    .request-product-card > .card-body > .card-text {
        font-size: 14px !important;
        line-height: 14px !important;
    }

    .request-product-card > .card-body > .input-group > div > input {
        width: 7vw !important;
    }

    .Product-Title, .Product-value {
        font-size: 28px;
    }
}

@media (max-width: 600px) {
    .request-product-card > .col-md-3 > button {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 550px) {
    .product-slider-image-container > div {
        width: 50%;
        height: 15vh;
    }
}

@media (max-width: 400px) {
    .product-slider-image-container > div {
        width: 100%;
        height: 10vh;
    }
}
