.grid-containernew {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  
.grid-itemnew {
    flex: 0 0 calc(30% - 40px); 
    margin-right: 20px;
    margin-bottom: 20px;
    height: 100%;
    border-radius: 12px;
}

.contentlink {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    opacity: 0;
    transition: .5s opacity ease-in-out;
    border-radius: 12px;
    width: 25vh;
    height: 25vh;
    margin-top: -25vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-itemnew:hover .contentlink {
    opacity: 1;
}

.grid-itemnew:hover .NavLink {
    opacity: 1;
}

.lnk {
    color: #FFFF;
    text-decoration: none;
    font-family: 'Inter';
}

.lnk:hover {
    text-decoration: none;
    color: #FFFF;
    font-size: 18px;
    transition: 1s;
}

.imgnew {
    width: 25vh; 
    height: 25vh; 
    object-fit: cover; 
    border-radius: 12px;
}

.prod {
    width: 60%;
}

.info {
    width: 20%;
}

.header-txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.navbar-light-header > .d-flex {
    padding-top: 2%;
}

a:hover.header-txt {
    color: var(--primary-color) !important;
}

.navbar {
    background: #FFFFFF;
}

.navbar-header {
    background: #FFFFFF;
}

.navbar-logo {
    transition: 1s;
}

.navbar-links {
    width: 60%;
    text-align: -webkit-center;
}

.nav-item > .nav-link {
    font-size: 14px;
}

.nav-item {
    margin: auto;
    width: fit-content;
}

.dropdown-menu {
    border-radius: 14px;
}

.offcanvas {
    font-family: 'Inter';
}

.Offcanvas-navlink-text {
    font-weight: 400;
    font-size: 36px;
    line-height: 58px;
    color: #777777;
    padding: 0;
    margin-bottom: 5%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.Offcanvas-navlink-text:hover, .Offcanvas-navlink-text.active {
    color: #194389;
}

.btn-close {
    margin-right: 9% !important;
    opacity: 1;
    box-shadow: none !important;
}

.desktop-header {
    display: block;
}

.mobile-header {
    display: none;
}

.mobile-offcanvas .nav-link.active, .mobile-offcanvas .nav-link.active > .links {
    color: #11448A;
}

.desktop-offcanvas {
    width: 80%;
    margin: auto;
}

.desktop-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link,
.mobile-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link {
    padding-left: 0;
}

.desktop-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link:hover,
.mobile-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link:hover,
.desktop-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link.active-category,
.mobile-offcanvas > div > div > .Product-container > .support-container > p > .home-product > .nav-link.active-category {
    color: #11448A;
}

.desktop-offcanvas > div > div > .Information-container > .support-container > p > .nav-link {
    padding-top: 0;
}

.desktop-offcanvas > div > div > .Information-container > .support-container > p > .nav-link.active > .links {
    color: #11448A;
}

.mobile-offcanvas {
    width: 80%;
    margin: 0 auto;
}

.mobile-offcanvas > div .search-container {
    width: 60vw;
}

.mobile-offcanvas > div .search-container > button {
    background: #11448A;
}

@media (max-width: 1304px) {
    .navbar-expand-lg .navbar-nav {
        width: auto;
    }
}

@media (max-width: 1100px) {
    #header > .container > .container {
        max-width: 100%;
    }
}

@media (max-width: 950px) {
    .desktop-header {
        display: none;
    }

    .mobile-header {
        display: block;
    }
}

@media (max-width: 991px) {
    .nav-item {
        margin: 1% auto;
    }
}

@media (max-width: 820px) {
    .navbar-links {
        width: 100%;
        margin: 25% 0;
    }

    .nav-item {
        margin: 5% auto;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-logo {
        margin-left: 23% !important;
        width: 60% !important;
    }

    .navbar-header {
        width: auto !important;
    }

    .navbar {
        position: fixed !important;
    }

    svg {
        height: 28px !important;
        width: 28px !important;
    }

    .Offcanvas-navlink-text {
        font-size: 24px !important;
    }

    #navbar-img {
        width: 200% !important;
        object-fit: cover;
    }

    #offcanvas-navlinks {
        margin-top: 20% !important;
    }

    #nav-dropdown {
        margin-left: -25% !important;
    }
}

@media (max-width: 600px) {
    .mobile-offcanvas > div .search-container {
        width: 70vw;
    }

    .mobile-offcanvas > .d-flex:last-child {
        display: block !important;
    }
}
